.logout-button {
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 34px;
  transition: background-color 0.3s;
}
button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.logout-button:hover {
  font-size: 34px;
}

.admin-mosque-details {
    font-family: Arial, sans-serif;
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Error message styling */
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Form elements and filters */
  .filters {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  
  .filters label {
    font-weight: bold;
    color: #555;
  }
  
  .filters select {
    padding: 8px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 150px;
  }
  
  .filters select:focus {
    outline: none;
    border-color: #5b9bd5;
  }
  
  /* Table for prayer times */
  .prayer-times-table {
    width: 100%;
    overflow-x: auto;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  th, td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ddd;
    font-size: 14px;
  }
  
  th {
    background-color: #f16876;
    color: white;
  }
  
  tr:nth-child(even) {
    background-color: #f1f1f1;
  }
  
  input[type="time"] {
    width: 100px;
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  input[type="time"]:focus {
    outline: none;
    border-color: #5b9bd5;
  }
  
  /* CSV upload section */
  .csv-upload {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .csv-upload input[type="file"] {
    padding: 8px;
    font-size: 14px;
  }
  
  .csv-upload button {
    background-color: #f16876;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .csv-upload button:hover {
    background-color: #e04e63;
  }
  
  /* Confirmation modal styling */
  .confirmation-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }
  
  .confirmation-modal p {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
  }
  
  .confirmation-modal button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin: 0 10px;
  }
  
  .confirmation-modal button:hover {
    background-color: #45a049;
  }
  
  .confirmation-modal button.cancel {
    background-color: #f44336;
  }
  
  .confirmation-modal button.cancel:hover {
    background-color: #da190b;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .filters {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .filters label {
      margin-bottom: 10px;
    }
  
    .csv-upload {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .csv-upload button {
      width: 100%;
      margin-top: 10px;
    }
  
    table {
      width: 100%;
    }
  }
  