.parking-info {
    padding: 70px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center; /* Centering all text inside the component */
  }
  
  .mosque-name {
    font-size: 28px;
    color: #F16876;
    margin-bottom: 20px;
  }
  
  .parking-info-container {
    background-color: #fff;
    border-radius: 8px;
    padding: 20px; /* Increased padding for a better look */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    margin: 0 auto; /* Center the container */
    max-width: 800px; /* Set a max width for better readability */
  }
  
  .info-title {
    font-size: 24px;
    color: #2980b9;
    margin-bottom: 15px;
  }
  
  .instruction-list {
    padding-left: 0; /* Remove indentation for ordered list */
    list-style-position: inside; /* Make numbers appear inside the list */
    margin: 0; /* Remove default margin */
    text-align: left;
  }
  
  .instruction-item {
    border-radius: 5px;
    padding: 10px;
    margin: 8px 0;
    transition: background-color 0.3s;
    background-color: #f0f8ff; /* Light background for items */
  }
  
  .instruction-item:hover {
    background-color: #e0e0e0; /* Hover effect */
  }
  
  .no-instructions {
    color: #ff0019;
    font-style: italic;
    margin-top: 20px; /* Added margin for spacing */
  }
  
  /* Centering paragraphs globally */
  .parking-info p {
    text-align: center; /* Center the paragraph text */
    margin: 10px 0; /* Spacing around paragraphs */
  }
  