/* src/components/MosquesList.css */

.mosques-list {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.loading-spinner {
  display: block;
  margin: 50px auto;
  border: 3px solid rgba(0, 4, 254, 0.1);
  border-left-color: #333;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.direction-icon {
  margin-right: 8px;
  color: #e74c3c;
  cursor: pointer;
  transition: transform 0.2s;
}

.direction-icon:hover {
  transform: scale(1.6);
}

.mosque-link {
  color: #333;
  text-decoration: none;
}

.list-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  color: #2d3a45;
  margin-bottom: 30px;
  border-bottom: 3px solid #2980b9;
  display: inline-block;
  padding-bottom: 10px;
}

.Next-Salah {
  color: #1f78c1;
  font-weight: 700;
}

.error-message {
  color: #e74c3c;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.mosques-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  background-color: #fff;
  position: relative; /* Ensure the table is positioned for sticky headers */
}

.mosques-table th,
.mosques-table td {
  padding: 15px;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.mosques-table th {
  background-color: #3498db;
  color: white;
  font-size: 1.1rem;
  text-transform: uppercase;
  position: sticky; /* Make header sticky */
  top: 0; /* Position it at the top */
  z-index: 10; /* Ensure it stays above other content */
}

.mosques-table td {
  background-color: #f9f9f9;
}

.mosques-table tr:nth-child(even) td {
  background-color: #f2f6f9;
}

.mosques-table tr:hover td {
  background-color: #e6eff7;
}

.mosque-link {
  color: #3498db;
  font-weight: 500;
  text-decoration: none;
  transition: color 0.3s ease;
}

.mosque-link:hover {
  color: #1f78c1;
  text-decoration: underline;
}

.mosque-info {
  display: flex;
  align-items: center;
}

/* Address alignment and styling */
.mosque-address {
  text-align: left; /* Align address to the left */
  margin-top: 5px;
  font-size: 0.9rem;
  color: #555;
}

@media screen and (max-width: 768px) {
  .mosques-list {
    padding: 20px;
  }

  .list-title {
    font-size: 2rem;
  }

  .mosques-table th,
  .mosques-table td {
    padding: 10px;
    font-size: 0.9rem;
  }
}
