.upload-csv-page {
    max-width: 900px;
    margin: 10px  auto 10px auto;margin-top: 80px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .upload-csv-page h1 {
    text-align: center;
    margin-bottom: 80px;
  }
  
  .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .upload-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .upload-section input {
    margin-bottom: 10px;
    padding: 8px;
    width: 100%;
    max-width: 400px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .upload-section button {
    padding: 10px 20px;
    background-color: #d15563;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-section button:disabled {
    background-color: #cccccc;
  }
  
  .upload-section button:hover {
    background-color: #b84c58;
  }
  
  .download-link {
    color: #d15563;
    text-decoration: none;
    margin-top: 10px;
  }
  
  .download-link:hover {
    text-decoration: underline;
  }
  
  button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #d15563;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button:hover {
    background-color: #b84c58;
  }
  