/* src/components/MosqueDetails.css */
.mosque-details {
  max-width: 1100px;
  margin: 0 auto;
  padding: 30px;
}
.friday-highlight {
  color: #ff007b;
}

.home-icon{
  color: #e74c3c;
  font-size: 18px;
  cursor: pointer;
}

.current-time {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 10px;
  font-weight: bold;
}

.highlight {
  background-color: #f2f2c2; /* Light yellow background */
  font-weight: bold; /* Bold text */
  color: #e74c3c;
}

.mosque-name {
  font-size: 24px;
  margin-bottom: 8px;
}

.mosque-address {
  font-size: 16px;
  margin-bottom: 16px;
  color: #555;
  font-weight: bold;
  color: #2980b9;
  align-items: center;
  text-align: center;
}

.error-message {
  color: #e74c3c;
  font-weight: bold;
}

.details-container {
  text-align: center;
}

.mosque-name {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.prayer-times-title {
  font-size: 1.5rem;
  color: #34495e;
  margin-bottom: 20px;
}

.prayer-times-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  position: relative; 
  font-size: 14px;

}

.prayer-times-table th,
.prayer-times-table td {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: center;
}

.prayer-times-table th {
  background-color: #2980b9;
  color: white;
  position: sticky; /* Make header sticky */
  top: 0; /* Position it at the top */
  z-index: 10; /* Ensure it stays above other content */
  font-weight: bold;

}

.parking-button {
  color: #F16876; /* Text color */
  padding: 12px; /* Vertical padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size */
  cursor: pointer; /* Pointer cursor on hover */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  text-align: center;
}

.parking-button:hover {
  transform: scale(1.05); /* Slightly grow the button on hover */
}

.parking-button:focus {
  outline: none; /* Remove outline when focused */
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5); /* Add focus shadow for accessibility */
}

.parking-icon {
  font-size: 20px; /* Icon size */
}
  .date-column {
    background-color: #f4f4ef; 
}

.unavailable {
  color: #e74c3c;
  font-weight: bold;
}

.parking-info {
  font-size: 1em;
}

.parking-info-title {
  margin-top: 20px;
  font-size: 1.5em;
  color: #333;
}
