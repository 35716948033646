.admin-login-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    font-family: Arial, sans-serif;
  }
  
  .admin-login-container h1 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .admin-login-form {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    margin-bottom: 15px;
  }
  
  .input-group label {
    display: block;
    margin-bottom: 5px;
    color: #555;
    font-weight: bold;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    box-sizing: border-box;
    transition: border-color 0.2s;
  }
  
  .input-group input:focus {
    border-color: #f16876;
  }
  
  .password-wrapper {
    display: flex;
    align-items: center;
  }
  
  .password-wrapper input {
    flex: 1;
  }
  
  .toggle-password-btn {
    margin-left: 8px;
    padding: 8px 12px;
    background-color: #f16876;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
  }
  
  .toggle-password-btn:hover {
    background-color: #d15965;
  }
  
  .login-btn {
    background-color: #f16876;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .login-btn:disabled {
    background-color: #aaa;
    cursor: not-allowed;
  }
  
  .login-btn:hover:not(:disabled) {
    background-color: #cb5662;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
  }
  